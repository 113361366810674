import { Reducer } from 'redux'
import { AppState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'

export const initialState: AppState = {
    init: false,
    zoom: 6,
    groups: [],
    refreshEstablishmentsInProgress: false,
    previousPath: '/',
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setGoogleMapInitialized): {
            const isGoogleMapInitialized = action.payload
            return {
                ...state,
                isGoogleMapInitialized,
            }
        }
        case getType(actions.setGroups): {
            const groups = action.payload
            return {
                ...state,
                groups,
            }
        }
        case getType(actions.setBounds): {
            const bounds = action.payload
            return {
                ...state,
                bounds,
            }
        }
        case getType(actions.setZoom): {
            const zoom = action.payload
            return {
                ...state,
                zoom,
            }
        }
        case getType(actions.setDefaultPosition): {
            const defaultPosition = action.payload
            return {
                ...state,
                defaultPosition,
            }
        }
        case getType(actions.setCurrentOvarEstablishment): {
            const currentOvarEstablishment: OvarEstablishmentModel = action.payload
            const { previousPath } = state
            if (typeof window !== 'undefined') {
                if (currentOvarEstablishment === undefined) {
                    window.history.pushState({}, '', previousPath)
                } else {
                    window.history.pushState({}, '', '/restaurant/' + currentOvarEstablishment.uri + '/')
                }
            }
            return {
                ...state,
                currentOvarEstablishment,
            }
        }
        case getType(actions.setCurrentRemplisVertEstablishment): {
            const currentRemplisVertEstablishment: RemplisVertEstablishmentModel = action.payload
            const { previousPath } = state
            if (typeof window !== 'undefined') {
                if (currentRemplisVertEstablishment === undefined) {
                    window.history.pushState({}, '', previousPath)
                } else {
                    window.history.pushState(
                        {},
                        '',
                        '/remplisvert/' + currentRemplisVertEstablishment.uri + '/',
                    )
                }
            }
            return {
                ...state,
                currentRemplisVertEstablishment,
            }
        }
        case getType(actions.setCurrentSuggProEstablishment): {
            const currentSuggProEstablishment = action.payload
            return {
                ...state,
                currentSuggProEstablishment,
            }
        }
        case getType(actions.setPreviousPath): {
            const previousPath = action.payload
            return {
                ...state,
                previousPath,
            }
        }
        case getType(actions.setCurrentGroup): {
            const currentGroup = action.payload
            return {
                ...state,
                currentGroup,
            }
        }
        case getType(actions.setRefreshEstablishmentsInProgress): {
            const refreshEstablishmentsInProgress = action.payload
            return {
                ...state,
                refreshEstablishmentsInProgress,
            }
        }
        default:
            return state
    }
}

export { reducer as appReducer }
