import * as React from 'react'
import { ComponentType } from 'react'
import { RemplisVertProvider, StorageInterface } from '@sugg-gestion/react-remplisvert'
import { useStore } from 'react-redux'

export class WebStorage implements StorageInterface {
    storage: Storage

    constructor(storage: Storage) {
        this.storage = storage
    }

    getItem(key: string): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            resolve(this.storage.getItem(key))
        })
    }

    setItem(key: string, value: string): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve(this.storage.setItem(key, value))
        })
    }

    removeItem(key: string): Promise<void> {
        return new Promise<void>((resolve) => {
            resolve(this.storage.removeItem(key))
        })
    }
}

class DumbStorage implements Storage {
    values: { [key: string]: string | null }

    readonly length: number

    clear(): void {}

    getItem(key: string): string | null {
        return this.values[key]
    }

    key(index: number): string | null {
        let i = 0
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key)) {
                if (i === index) {
                    return this.values[key]
                }
            }
            i++
        }
        return null
    }

    removeItem(key: string): void {
        delete this.values[key]
    }

    setItem(key: string, value: string): void {
        this.values[key] = value
    }
}

const dumbStorage = new DumbStorage()

const withRemplisVert = (ComposedComponent: ComponentType) => {
    const WithRemplisVert: React.FC = ({ ...props }) => {
        const store = useStore()
        let endpoint = process.env.GATSBY_REMPLISVERT_ENDPOINT
        if (endpoint === undefined) {
            endpoint = ''
        }
        return (
            <RemplisVertProvider
                store={store}
                options={{
                    apiEndpoint: endpoint,
                    persistentStorage:
                        typeof window !== 'undefined'
                            ? new WebStorage(localStorage)
                            : new WebStorage(dumbStorage),
                    sessionStorage:
                        typeof window !== 'undefined'
                            ? new WebStorage(localStorage)
                            : new WebStorage(dumbStorage),
                }}
            >
                <ComposedComponent {...props} />
            </RemplisVertProvider>
        )
    }
    return WithRemplisVert
}

export default withRemplisVert
