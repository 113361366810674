import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'
import * as React from 'react'
import { ComponentType } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { GroupModel } from '../../core/models/groupModel'
import { initialState as initialAppState } from '../../core/store/app/reducer'
import { createMiddleware } from '../../core/store/middleware'
import { createRootReducer } from '../../core/store/reducers'

interface OutputProps {
    sagaMiddleware: SagaMiddleware
}

interface InputProps {
    pageContext?: {
        currentOvarEstablishment?: OvarEstablishmentModel
        currentRemplisVertEstablishment?: RemplisVertEstablishmentModel
        groups: Array<GroupModel>
    }
}

const withStoreProvider = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithStoreProvider: React.FC<InputProps> = ({ ...props }) => {
        const appState = initialAppState
        const { pageContext } = props
        if (pageContext) {
            appState.currentOvarEstablishment = pageContext.currentOvarEstablishment
            appState.currentRemplisVertEstablishment = pageContext.currentRemplisVertEstablishment
            appState.groups = pageContext.groups
            if (appState.currentOvarEstablishment) {
                appState.zoom = 16
                appState.defaultPosition = {
                    lat: appState.currentOvarEstablishment.location.latitude,
                    lng: appState.currentOvarEstablishment.location.longitude,
                }
            } else if (appState.currentRemplisVertEstablishment) {
                appState.zoom = 16
                appState.defaultPosition = {
                    lat: appState.currentRemplisVertEstablishment.location.latitude,
                    lng: appState.currentRemplisVertEstablishment.location.longitude,
                }
            }
        }

        const rootReducer = createRootReducer(appState)
        const sagaMiddleware = createSagaMiddleware()
        const store = createStore(rootReducer, createMiddleware(sagaMiddleware))
        return (
            <Provider store={store}>
                <ComposedComponent {...props} sagaMiddleware={sagaMiddleware} />
            </Provider>
        )
    }
    return WithStoreProvider
}

export default withStoreProvider
