import { AxiosResponse } from 'axios'
import { client } from '../client'
import { SurroundingMenuBoardsRequest, SurroundingMenuBoardsResponse } from './types'

export const getSurroundingMenuBoards = (
    parameters: SurroundingMenuBoardsRequest,
): Promise<SurroundingMenuBoardsResponse> => {
    const url = '/menuboards/surrounding'

    return client
        .post(url, parameters, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<SurroundingMenuBoardsResponse>) => {
            if (result.data) {
                return result.data
            }
            throw Error()
        })
}
