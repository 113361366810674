import * as React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import useStyles from './loading.css'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

interface Props {
    withLogo?: boolean
}

const Loading: React.FC<Props> = ({ withLogo = true }) => {
    const classes = useStyles()
    const {
        file: {
            childImageSharp: { fixed: logo },
        },
    } = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={classes.main}>
            {withLogo && (
                <div className={classes.logo}>
                    <Img fixed={logo} alt="OnVaAuResto" />
                </div>
            )}
            <div className={classes.indicator}>
                <CircularProgress size="4rem" />
            </div>
            <Typography>Chargement en cours...</Typography>
        </div>
    )
}

export default Loading
