import * as React from 'react'
import MainLayout from './src/containers/mainLayout'
import withMuiTheme from './src/containers/hoc/withMuiTheme'
import withStoreProvider from './src/containers/hoc/withStoreProvider'
import { compose } from 'redux'
import withOnVaAuResto from './src/containers/hoc/withOnVaAuResto'
import withSaga from './src/containers/hoc/withSaga'
import withViewHeight from './src/containers/hoc/withViewHeight'
import withRemplisVert from './src/containers/hoc/withRemplisVert'

const Root = compose(
    withMuiTheme,
    withStoreProvider,
    withOnVaAuResto,
    withRemplisVert,
    withSaga,
    withViewHeight,
)(MainLayout)

export const wrapPageElement = ({ element, props }) => {
    return <Root pageContext={props.pageContext}>{element}</Root>
}
