import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        logo: {
            'padding': theme.spacing(2),
            '& > img': {
                width: '100%',
                maxWidth: '16rem',
            },
        },
        indicator: {
            padding: theme.spacing(2),
        },
    }),
)

export default useStyles
