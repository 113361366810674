import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

import 'typeface-open-sans'

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#f06023',
        },
        secondary: {
            main: '#fff',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
    },
    overrides: {
        MuiTypography: {
            body2: {
                'fontSize': '0.8rem',
                '@media (min-width:960px)': {
                    fontSize: '0.875rem',
                },
            },
        },
    },
})

theme = responsiveFontSizes(theme)

export default theme
