import { AxiosResponse } from 'axios'
import { client } from '../client'
import { EstablishmentRequest, EstablishmentResponse } from './types'

export const getEstablishment = (parameters: EstablishmentRequest): Promise<EstablishmentResponse> => {
    const url = '/establishment/:id/public'.replace(':id', parameters.id.toString())

    return client
        .get(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((result: AxiosResponse<EstablishmentResponse>) => {
            if (result.data) {
                return result.data
            }
            throw Error()
        })
}
