import { Reducer } from 'redux'
import { EstablishmentsState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../../actions'
import { EstablishmentModel } from '../../../models/establishmentModel'

const initialState: EstablishmentsState = {
    establishments: [],
}

const reducer: Reducer<EstablishmentsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setEstablishment): {
            const establishment: EstablishmentModel = action.payload
            const { establishments } = state
            const establishmentIndex = establishments.findIndex((current) => current.id === establishment.id)
            if (establishmentIndex !== -1) {
                establishments[establishmentIndex] = establishment
            } else {
                establishments.push(establishment)
            }

            return {
                ...state,
                establishments,
            }
        }
        default:
            return state
    }
}

export { reducer as establishmentsReducer }
