import axios from 'axios'

export const client = axios.create({
    baseURL: process.env.GATSBY_SUGG1144_ENDPOINT,
    responseType: 'json',
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data',
        'app': 'sugg_1144',
    },
})
