import { graphql, useStaticQuery, withPrefix } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import SmartBanner from 'react-smartbanner'

const MainLayout: React.FC = ({ children }) => {
    const {
        app: {
            childImageSharp: { fixed: app },
        },
    } = useStaticQuery(graphql`
        query {
            app: file(relativePath: { eq: "app.png" }) {
                childImageSharp {
                    fixed(width: 171) {
                        src
                    }
                }
            }
        }
    `)
    return (
        <React.Fragment>
            <Helmet>
                <title>On Va Au Resto, trouvez le restaurant ouvert le plus proche</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <meta
                    name="description"
                    content="La page qui propose la carte de France de tous les groupes officiels On Va Au Resto, afin de soutenir les restaurateurs localement"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="On Va Au Resto" />
                <meta
                    property="og:title"
                    content="On Va Au Resto, trouvez le restaurant ouvert le plus proche"
                />
                <meta
                    property="og:description"
                    content="La page qui propose la carte de France de tous les groupes officiels On Va Au Resto, afin de soutenir les restaurateurs localement"
                />
                <style>
                    {'.smartbanner-icon { background-image: url(' + withPrefix(app.src) + ') !important}'}
                </style>
            </Helmet>
            <SmartBanner
                title="On Va Au Resto - RemplisVert"
                author="Sugg-Gestion"
                button="Installer"
                storeText={{ android: 'Sur Google Play', ios: "dans l'App Store" }}
                price={{ android: 'GRATUIT', ios: 'OBTENIR' }}
            />
            {children}
        </React.Fragment>
    )
}

export default MainLayout
