import { Reducer } from 'redux'
import { unionBy } from 'lodash'
import { MenuBoardsState, SetSurroundingMenuBoards } from './types'
import { getType } from 'typesafe-actions'
import actions from '../../actions'
import * as turf from '@turf/turf'

const initialState: MenuBoardsState = {
    surroundingMenuBoards: [],
}

const reducer: Reducer<MenuBoardsState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.setSurroundingMenuBoards): {
            const { surrounding, payload }: SetSurroundingMenuBoards = action.payload
            const { surroundingMenuBoards, coveredArea } = state
            const combineSurroundingMenuBoards = unionBy(surroundingMenuBoards, surrounding, 'id')

            const x1 = payload.northEast.longitude
            const y1 = payload.northEast.latitude
            const x2 = payload.southWest.longitude
            const y2 = payload.southWest.latitude
            const area = turf.multiPolygon([
                [
                    [
                        [x1, y1],
                        [x1, y2],
                        [x2, y2],
                        [x2, y1],
                    ],
                ],
            ])
            let newCoveredArea = area
            if (coveredArea) {
                const collection = turf.featureCollection([coveredArea, area])
                newCoveredArea = turf.combine(collection)
            }

            return {
                ...state,
                surroundingMenuBoards: combineSurroundingMenuBoards,
                coveredArea: newCoveredArea,
            }
        }
        default:
            return state
    }
}

export { reducer as menuBoardsReducer }
