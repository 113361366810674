import appSagas from './app/sagas'
import { all, call } from 'redux-saga/effects'
import { onvaaurestoSaga } from '@sugg-gestion/react-onvaauresto'
import { remplisVertSaga } from '@sugg-gestion/react-remplisvert'

function* mainSaga() {
    yield all([call(appSagas), call(onvaaurestoSaga), call(remplisVertSaga)])
}

export default mainSaga
