import { combineReducers } from 'redux'
import { AppState } from './app/types'
import { appReducer } from './app/reducer'
import { menuBoardsReducer } from './sugg1144/menuBoards/reducer'
import { MenuBoardsState } from './sugg1144/menuBoards/types'
import { EstablishmentsState } from './sugg1144/establishments/types'
import { establishmentsReducer } from './sugg1144/establishments/reducer'
import { onvaaurestoReducer, OnVaAuRestoState } from '@sugg-gestion/react-onvaauresto'
import { remplisVertReducer, RemplisVertState } from '@sugg-gestion/react-remplisvert'

export interface ApplicationState {
    app: AppState
    menuBoards: MenuBoardsState
    establishments: EstablishmentsState
    onvaauresto: OnVaAuRestoState
    remplisVert: RemplisVertState
}

export const createRootReducer = (appState: AppState) =>
    combineReducers<ApplicationState>({
        app: (state, action) => appReducer(state || appState, action),
        menuBoards: menuBoardsReducer,
        establishments: establishmentsReducer,
        onvaauresto: onvaaurestoReducer,
        remplisVert: remplisVertReducer,
    })
