import { createAction } from 'typesafe-actions'
import { AppActionTypes } from './types'
import { GroupModel } from '../../models/groupModel'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'
import { MenuBoardEstablishmentModel } from '../../models/menuBoardEstablishmentModel'

export const init = createAction(AppActionTypes.INIT)<boolean>()
export const setGoogleMapInitialized = createAction(AppActionTypes.SET_GOOGLE_MAP_INITIALIZED)<boolean>()

export const setGroups = createAction(AppActionTypes.SET_GROUPS)<Array<GroupModel>>()
export const setCurrentGroup = createAction(AppActionTypes.SET_CURRENT_GROUP)<GroupModel | undefined>()

export const setBounds = createAction(AppActionTypes.SET_BOUNDS)<google.maps.LatLngBounds | undefined>()
export const setZoom = createAction(AppActionTypes.SET_ZOOM)<number>()
export const setDefaultPosition = createAction(
    AppActionTypes.SET_DEFAULT_POSITION,
)<google.maps.LatLngLiteral>()

export const setRefreshEstablishmentsInProgress = createAction(
    AppActionTypes.SET_REFRESH_ESTABLISHMENTS_IN_PROGRESS,
)<boolean>()
export const setCurrentOvarEstablishment = createAction(
    AppActionTypes.SET_CURRENT_OVAR_ESTABLISHMENT,
)<OvarEstablishmentModel>()

export const setCurrentRemplisVertEstablishment = createAction(
    AppActionTypes.SET_CURRENT_REMPLISVERT_ESTABLISHMENT,
)<RemplisVertEstablishmentModel>()

export const setCurrentSuggProEstablishment = createAction(
    AppActionTypes.SET_CURRENT_SUGGPRO_ESTABLISHMENT,
)<MenuBoardEstablishmentModel>()
export const setPreviousPath = createAction(AppActionTypes.SET_PREVIOUS_PATH)<string>()
