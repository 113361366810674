import {
    actions as ovarActions,
    EstablishmentModel as OvarEstablishmentModel,
} from '@sugg-gestion/react-onvaauresto'
import {
    actions as remplisVertActions,
    EstablishmentModel as RemplisVertEstablishmentModel,
} from '@sugg-gestion/react-remplisvert'
import { Feature, MultiPolygon } from '@turf/helpers/lib/geojson'
import * as turf from '@turf/turf'
import { isEqual } from 'lodash'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { GroupModel } from '../../models/groupModel'
import { queries } from '../../resources/queries'
import actions from '../actions'
import { ApplicationState } from '../reducers'

function* appSagas() {
    yield takeLatest(getType(actions.setBounds), showCurrentGroup)
    yield takeEvery(getType(actions.setBounds), refreshEstablishments)
    yield takeEvery(getType(ovarActions.setEstablishments), refreshCurrentOvarEstablishment)
    yield takeEvery(getType(remplisVertActions.setEstablishments), refreshCurrentRemplisVertEstablishment)
}

function* showCurrentGroup() {
    const {
        bounds,
        zoom,
        groups,
    }: { bounds?: google.maps.LatLngBounds; zoom: number; groups: Array<GroupModel> } = yield select(
        ({ app }: ApplicationState) => ({
            bounds: app.bounds,
            zoom: app.zoom,
            groups: app.groups,
        }),
    )
    if (bounds && zoom >= parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
        const group = groups.find((group) => {
            const groupCircle = new google.maps.Circle({
                center: {
                    lat: group.latitude,
                    lng: group.longitude,
                },
                radius: group.radius * 1000,
            })
            return (
                groupCircle.getBounds().contains(bounds.getCenter()) &&
                google.maps.geometry.spherical.computeDistanceBetween(
                    groupCircle.getCenter(),
                    bounds.getCenter(),
                ) <=
                    group.radius * 1000
            )
        })
        yield put(actions.setCurrentGroup(group))
        return
    }
    yield put(actions.setCurrentGroup(undefined))
}

function* refreshEstablishments() {
    const {
        bounds,
        zoom,
        coveredArea,
        refreshEstablishmentsInProgress,
    }: {
        bounds?: google.maps.LatLngBounds
        zoom: number
        groups: Array<GroupModel>
        coveredArea?: Feature<MultiPolygon>
        refreshEstablishmentsInProgress: boolean
    } = yield select(({ app, menuBoards }: ApplicationState) => ({
        bounds: app.bounds,
        zoom: app.zoom,
        refreshEstablishmentsInProgress: app.refreshEstablishmentsInProgress,
        coveredArea: menuBoards.coveredArea,
    }))

    if (refreshEstablishmentsInProgress) {
        return
    }
    if (bounds && zoom >= parseInt(process.env.GATSBY_MINIMUM_ZOOM_DETAILS)) {
        // catch empty bounds
        if (
            bounds.getNorthEast().lat() === bounds.getSouthWest().lat() &&
            bounds.getNorthEast().lng() === bounds.getSouthWest().lng()
        ) {
            return
        }
        const payload = {
            categories: [],
            northEast: {
                latitude: bounds.getNorthEast().lat(),
                longitude: bounds.getNorthEast().lng(),
            },
            southWest: {
                latitude: bounds.getSouthWest().lat(),
                longitude: bounds.getSouthWest().lng(),
            },
        }
        if (coveredArea) {
            const points = turf.points([
                [payload.northEast.longitude, payload.northEast.latitude],
                [payload.southWest.longitude, payload.southWest.latitude],
            ])
            const ptsWithin = turf.pointsWithinPolygon(points, coveredArea)
            if (isEqual(ptsWithin, points)) {
                return
            }
        }
        yield put(actions.setRefreshEstablishmentsInProgress(true))
        try {
            const response = yield call(queries.getSurroundingMenuBoards, payload)
            yield put(
                actions.setSurroundingMenuBoards({
                    surrounding: response,
                    payload,
                }),
            )
            yield put(actions.setRefreshEstablishmentsInProgress(false))
        } catch (error) {
            console.error(error)
            yield put(actions.setRefreshEstablishmentsInProgress(false))
        }
    }
}

function* refreshCurrentOvarEstablishment(action: ActionType<typeof ovarActions.setEstablishments>) {
    const establishments = action.payload
    const {
        currentOvarEstablishment,
    }: {
        currentOvarEstablishment?: OvarEstablishmentModel
    } = yield select(({ app }: ApplicationState) => ({
        currentOvarEstablishment: app.currentOvarEstablishment,
    }))
    if (currentOvarEstablishment && establishments.length > 0) {
        const refreshedOvarEstablishment = establishments.find(
            (establishment) => establishment.id === currentOvarEstablishment.id,
        )
        if (refreshedOvarEstablishment) {
            yield put(actions.setCurrentOvarEstablishment(refreshedOvarEstablishment))
        }
    }
}

function* refreshCurrentRemplisVertEstablishment(
    action: ActionType<typeof remplisVertActions.setEstablishments>,
) {
    const establishments = action.payload
    const {
        currentRemplisVertEstablishment,
    }: {
        currentRemplisVertEstablishment?: RemplisVertEstablishmentModel
    } = yield select(({ app }: ApplicationState) => ({
        currentRemplisVertEstablishment: app.currentRemplisVertEstablishment,
    }))
    if (currentRemplisVertEstablishment && establishments.length > 0) {
        const refreshedRemplisVertEstablishment = establishments.find(
            (establishment) => establishment.id === currentRemplisVertEstablishment.id,
        )
        if (refreshedRemplisVertEstablishment) {
            yield put(actions.setCurrentRemplisVertEstablishment(refreshedRemplisVertEstablishment))
        }
    }
}

export default appSagas
