import * as React from 'react'
import { ComponentType } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from '../../core/theme'

const withMuiTheme = (ComposedComponent: ComponentType) => {
    const WithMuiTheme: React.FC = (props) => (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <ComposedComponent {...props} />
            </ThemeProvider>
        </React.Fragment>
    )
    return WithMuiTheme
}

export default withMuiTheme
