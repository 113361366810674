import { Feature, MultiPolygon } from '@turf/helpers/lib/geojson'
import {
    SurroundingMenuBoardsRequest,
    SurroundingMenuBoardsResponse,
} from '../../../resources/menuBoards/types'

export enum MenuBoardsActionTypes {
    SET_SURROUNDING = '@@sugg1144/menuBoards/SET_SURROUNDING',
}

export interface MenuBoardsState {
    surroundingMenuBoards: SurroundingMenuBoardsResponse
    coveredArea?: Feature<MultiPolygon>
}

export interface SetSurroundingMenuBoards {
    surrounding: SurroundingMenuBoardsResponse
    payload: SurroundingMenuBoardsRequest
}
