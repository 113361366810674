import { ComponentType, useEffect, useState } from 'react'
import * as React from 'react'
import Loading from '../../components/loading'

const withViewHeight = (ComposedComponent: ComponentType) => {
    const WithViewHeight: React.FC = ({ ...props }) => {
        const [start, setStart] = useState<boolean>(false)

        useEffect(() => {
            const calculVH = () => {
                let vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            }
            if (!start) {
                calculVH()

                window.addEventListener('resize', calculVH)
                setStart(true)
            }
        }, [start])
        if (start) {
            return (
                <div
                    style={{
                        height: 'calc((var(--vh, 1vh) * 100))',
                    }}
                >
                    <ComposedComponent {...props} />
                </div>
            )
        } else {
            return <Loading />
        }
    }
    return WithViewHeight
}

export default withViewHeight
