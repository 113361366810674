import { EstablishmentModel as RemplisVertEstablishmentModel } from '@sugg-gestion/react-remplisvert'
import { GroupModel } from '../../models/groupModel'
import { EstablishmentModel as OvarEstablishmentModel } from '@sugg-gestion/react-onvaauresto'
import { MenuBoardEstablishmentModel } from '../../models/menuBoardEstablishmentModel'

export enum AppActionTypes {
    INIT = '@@app/INIT',
    SET_GOOGLE_MAP_INITIALIZED = '@@app/SET_GOOGLE_MAP_INITIALIZED',
    SET_GROUPS = '@@app/SET_GROUPS',
    SET_CURRENT_GROUP = '@@app/SET_CURRENT_GROUP',
    SET_BOUNDS = '@@app/SET_BOUNDS',
    SET_ZOOM = '@@app/SET_ZOOM',
    SET_DEFAULT_POSITION = '@@app/SET_DEFAULT_POSITION',
    SET_REFRESH_ESTABLISHMENTS_IN_PROGRESS = '@@app/SET_REFRESH_ESTABLISHMENTS_IN_PROGRESS',
    SET_CURRENT_OVAR_ESTABLISHMENT = '@@app/SET_CURRENT_OVAR_ESTABLISHMENT',
    SET_CURRENT_REMPLISVERT_ESTABLISHMENT = '@@app/SET_CURRENT_REMPLISVERT_ESTABLISHMENT',
    SET_CURRENT_SUGGPRO_ESTABLISHMENT = '@@app/SET_CURRENT_SUGGPRO_ESTABLISHMENT',
    SET_PREVIOUS_PATH = '@@app/SET_PREVIOUS_PATH',
}

export interface AppState {
    init: boolean
    isGoogleMapInitialized?: boolean
    bounds?: google.maps.LatLngBounds
    zoom: number
    defaultPosition?: google.maps.LatLngLiteral
    groups: Array<GroupModel>
    currentGroup?: GroupModel
    refreshEstablishmentsInProgress: boolean
    currentOvarEstablishment?: OvarEstablishmentModel
    currentRemplisVertEstablishment?: RemplisVertEstablishmentModel
    currentSuggProEstablishment?: MenuBoardEstablishmentModel
    previousPath: string
}
